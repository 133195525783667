<template>
    <a-layout>
        <a-layout-sider>

            <a-anchor class="side-nav" :affix="false" @click="handleClick">
                <a-anchor-link v-for="(item,key) in partsMap" :key="key" :href="item.keys[0]" :title="item.name" />
            </a-anchor>
        </a-layout-sider>
        <a-layout-content>
            <h1 class="h1">偏相关分析结果</h1>
            <div class="result-area" id="varsProduce">
                <divider-title name="变量说明" />
                <div class="varlist">
                    <div v-for="(item,key) in varsProduce" :key="key">【{{item.typeName}}】{{item.key}}{{item.value}}</div>
                </div>
            </div>

            <div class="result-area" id="descStats">
                <divider-title name="描述性统计" />
                <a-table v-if="result&&result.partialBO&&result.partialBO.descStats" :dataSource="result.partialBO.descStats" :rowKey="(record,index)=>index" size="middle" :pagination="false">
                    <a-table-column key="varName" title="变量名" data-index="varName" :width="100" />
                    <a-table-column key="xmean" title="均值" data-index="xmean" align="right" :width="150">
                        <span slot-scope="text">{{toFixedNum(text)}}</span>
                    </a-table-column>
                    <a-table-column key="xstd" title="标准差" data-index="xstd" align="right" :width="150">
                        <span slot-scope="text">{{toFixedNum(text)}}</span>
                    </a-table-column>
                    <a-table-column key="xrealNum" title="样本个数" data-index="xrealNum" align="right" :width="150" />
                    <a-table-column key="blank" title="" data-index="blank">
                    </a-table-column>
                </a-table>
            </div>

            <div class="result-area" id="pcCoefficient">
                <divider-title name="偏相关系数" />
                <a-table 
                    :columns="pData.columns" 
                    :data-source="pData.list" 
                    :rowKey="(record,index)=>index" 
                    :pagination="false" 
                    size="middle"
                    >
                    <template slot="footer">
                        控制变量：{{this.result.partialBO.pcCoefficient.controlVarLst.join(' ')}}
                    </template>
                </a-table>
            </div>

            <div class="result-area" id="zeroOrderCoefficient">
                <divider-title name="零阶相关系数" />
                <a-table 
                    :columns="zeroData.columns" 
                    :data-source="zeroData.list" 
                    :rowKey="(record,index)=>index" 
                    :pagination="false" 
                    size="middle"
                    :scroll="{x:zeroData.columns.length>10?2500:true,y:zeroData.list.length>10?500:true}" />
            </div>

            <div class="result-area">
                <a-button type="primary" @click="download(0)">下载WORD</a-button>
                <a-button type="primary" @click="download(1)">下载PDF</a-button>
            </div>
            
        </a-layout-content>
    </a-layout>
</template>

<script>
import config from '../../until/configs';
import { getSessionItem } from '../../until/tools';
import DividerTitle from './components/DividerTitle.vue';
export default {
    components:{DividerTitle},
    data() {
        return {
            partsMap:[
                {name: '变量说明', keys: ['varsProduce']},
                {name: '描述性统计', keys: ['descStats']},
                {name: '偏相关系数', keys: ['pcCoefficient']},
                {name: '零阶相关系数', keys: ['zeroOrderCoefficient']}
            ]
        }
    },
    computed:{
        result() {
            let data = getSessionItem('analysis_partial2');
            return data.analysis_partial2;
        },
        varsProduce() {
            if(this.result&&this.result.partialBO&&this.result.partialBO.varsProduce) {
                return this.result.partialBO.varsProduce;
            } else {
                return []
            }
        },
        pData() {
            if(this.result&&this.result.partialBO&&this.result.partialBO.pcCoefficient) {
                let data = this.getPData(this.result.partialBO.pcCoefficient);
                return data;
            } else {
                return {
                    columns:[],
                    list:[]
                }
            }
        },
        zeroData() {
            if(this.result&&this.result.partialBO&&this.result.partialBO.zeroOrderCoefficient) {
                let data = this.getTableData(this.result.partialBO.zeroOrderCoefficient);
                return data;
            } else {
                return {
                    columns:[],
                    list:[]
                }
            }
        }
    },
    methods:{
        handleClick(e,link) {
            e.preventDefault();
            var srcolls = document.getElementById(link.href);
            srcolls.scrollIntoView({block: 'start', behavior: 'smooth'});
        },
        toFixedNum(num) {
            return num.toFixed(4);
        },

        getPData(obj) {
            let result = {};
            let columns = [
                {
                    dataIndex: 'varname', 
                    key: 'varname',
                    title:'',
                    width:50,
                    customRender:(value,row,key)=>{
                        let rowSpan = 0;
                        if(key%3===0) {
                            rowSpan = 3;
                        }
                        return {attrs:{rowSpan:rowSpan},children:value}
                    }
                },
                {
                    dataIndex: 'name', 
                    key: 'name',
                    width:150,
                },
            ];
            obj.varLst.forEach(item=>{
                columns.push({dataIndex:item,key:item,title:item,align:'right',width:150});
            });
            columns.push({dataIndex:'blank',key:'blank',title:'',align:'right'});
            result.columns = columns;
            let arr = [];
            let arr2 = [...obj.varLst,...obj.controlVarLst];
            obj.items.forEach((item,key)=>{
                let ar1 = {name:'相关系数'};
                ar1.varname = arr2[key];
                let ar2 = {name:'Sig.('+this.result.signName+')'};
                ar2.varname = arr2[key];
                let ar3 = {name:'自由度'};
                ar3.varname = arr2[key];
                item.forEach((i,k)=>{
                    ar1[arr2[k]] = i.correlations?i.correlations.toFixed(4):'-';
                    ar2[arr2[k]] = i.sigCorrelations?i.sigCorrelations.toFixed(4):'-';
                    ar3[arr2[k]] = i.df?i.df.toFixed(4):0;
                });
                arr.push(ar1);
                arr.push(ar2);
                arr.push(ar3);
            });
            result.list = arr;
            return result;
        },

        getTableData(obj) {
            let result = {};
            let columns = [
                {
                    dataIndex: 'varname', 
                    key: 'varname',
                    title:'',
                    fixed:'left',
                    width:50,
                    customRender:(value,row,key)=>{
                        let rowSpan = 0;
                        if(key%3===0) {
                            rowSpan = 3;
                        }
                        return {attrs:{rowSpan:rowSpan},children:value}
                    }
                },
                {
                    dataIndex: 'name', 
                    key: 'name',
                    fixed:'left',
                    width:150
                },
            ];
            obj.varLst.forEach(item=>{
                columns.push({dataIndex:item,key:item,title:item,align:'right',width:100})
            });
            obj.controlVarLst.forEach(item=>{
                columns.push({dataIndex:item,key:item,title:item,align:'right',width:100})
            });
            columns.push({dataIndex:'blank',key:'blank',title:'',align:'right'});
            result.columns = columns;
            let arr = [];
            let arr2 = [...obj.varLst,...obj.controlVarLst];
            obj.items.forEach((item,key)=>{
                let ar1 = {name:'相关系数'};
                ar1.varname = arr2[key];
                let ar2 = {name:'Sig.('+this.result.signName+')'};
                ar2.varname = arr2[key];
                let ar3 = {name:'自由度'};
                ar3.varname = arr2[key];
                item.forEach((i,k)=>{
                    ar1[arr2[k]] = i.correlations?i.correlations.toFixed(4):'-';
                    ar2[arr2[k]] = i.sigCorrelations?i.sigCorrelations.toFixed(4):'-';
                    ar3[arr2[k]] = i.df?i.df.toFixed(4):0;
                });
                arr.push(ar1);
                arr.push(ar2);
                arr.push(ar3);
            });
            result.list = arr;
            return result;
        },
        download(type) {
            let url = config.analysisResultDownload+'?action='+this.result.action+'&sheetId='+this.result.sheetId+'&type='+type+'&sid='+this.result.sid;
            window.open(url, '_blank');
        }
    }
}
</script>